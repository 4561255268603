import http from "./http";

const Usuario = {
  findAll: async (payload) => {
    return new Promise((resolve, reject) => {
      http({
        method: "GET",
        url: `usuarios`,
        params: payload,
      })
        .then((resp) => {
          resolve(resp.data);
        })
        .catch((error) => {
          reject(error.response.data);
        });
    });
  },

  findAllByProjetoId: async (payload) => {
    return new Promise((resolve, reject) => {
      http({
        method: "GET",
        url: `usuarios/projeto`,
        params: payload,
      })
        .then((resp) => {
          resolve(resp.data);
        })
        .catch((error) => {
          reject(error.response.data);
        });
    });
  },

  findById: async (payload) => {
    return new Promise((resolve, reject) => {
      http({
        method: "GET",
        url: `usuarios?id=${payload.id}`,
      })
        .then((resp) => {
          resolve(resp.data);
        })
        .catch((error) => {
          reject(error.response.data);
        });
    });
  },

  save: async (payload) => {
    return new Promise((resolve, reject) => {
      http({
        method: "POST",
        url: `usuarios`,
        data: payload,
      })
        .then((resp) => {
          resolve(resp.data);
        })
        .catch((error) => {
          reject(error.response.data);
        });
    });
  },
  alterarSenha: async (payload) => {
    return new Promise((resolve, reject) => {
      http({
        method: "PUT",
        url: `usuarios/alterarsenha`,
        data: payload,
      })
        .then((resp) => {
          resolve(resp.data);
        })
        .catch((error) => {
          reject(error.response.data);
        });
    });
  },
  delete: async (payload) => {
    return new Promise((resolve, reject) => {
      http({
        method: "DELETE",
        url: `usuarios`,
        data: { ...payload },
      })
        .then((resp) => {
          resolve(resp.data);
        })
        .catch((error) => {
          reject(error.response.data);
        });
    });
  },
};

export default Usuario;

<template>
  <v-form lazy-validation ref="form" @submit.prevent="alterar">
    <v-card max-width="400" class="mx-auto mt-10">
      <v-card-text>
        <v-row>
          <v-col cols="12">
            <v-text-field
              label="Nova Senha"
              :type="mostrarSenha ? 'text' : 'password'"
              outlined
              v-model="form.senha"
              :rules="[rules.required]"
              dense
              :append-icon="mostrarSenha ? mdiEyeOutline : mdiEyeOffOutline"
              @click:append="mostrarSenha = !mostrarSenha"
              :color="'primary'"
            ></v-text-field>
            <v-text-field
              label="Confirma Senha"
              :type="mostrarConfirmaSenha ? 'text' : 'password'"
              outlined
              v-model="form.confirmaSenha"
              :rules="[rules.required]"
              dense
              :append-icon="
                mostrarConfirmaSenha ? mdiEyeOutline : mdiEyeOffOutline
              "
              @click:append="mostrarConfirmaSenha = !mostrarConfirmaSenha"
              :color="'primary'"
            ></v-text-field>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                depressed
                :color="'primary'"
                type="submit"
                :loading="loading"
                :disabled="loading"
                :dark="!loading"
              >
                Alterar
              </v-btn>
            </v-card-actions>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-form>
</template>

<script>
import { mdiEyeOutline, mdiEyeOffOutline } from "@mdi/js";
import { mapActions, mapGetters } from "vuex";
import Usuario from "@/service/usuario";
export default {
  name: "Cadastro",
  data: () => ({
    rules: {
      required: (value) => !!value || "Obrigatório",
    },
    form: {
      senha: "",
      confirmaSenha: "",
    },
    mostrarSenha: false,
    mostrarConfirmaSenha: false,
    mdiEyeOutline,
    mdiEyeOffOutline,
    loading: false,
  }),
  computed: {
    ...mapGetters(["getAuth"]),
  },
  methods: {
    ...mapActions(["logout"]),
    async alterar() {
      const valido = this.$refs.form.validate();
      if (!valido) return;

      if (this.form.senha != this.form.confirmaSenha) {
        this.$notify({
          title: "Erro",
          text: "As senhas não conferem",
          type: "error",
        });
        return;
      }

      await Usuario.alterarSenha({
        id: this.getAuth.id,
        senha: this.form.senha,
      });

      this.$notify({
        type: "success",
        title: "Sucesso",
        text: "Sua senha foi alterada",
      });
      this.logout();
    },
  },
};
</script>
<style scoped>
a {
  text-decoration: none;
}
form input[type="text"] {
  background-color: aqua;
}
</style>
